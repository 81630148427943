<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      want_to_know_more: 'Want to know more?',
      request_a: 'Request a',
      demo: 'demo',
    },

    jp: {
      want_to_know_more: 'もっと詳しくは',
      request_a: '見積書を',
      demo: '頼みましょう',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
</script>

<div class="px-4 my-16">
  <div class="bg-pink-200 px-4 py-5 sm:p-6">
    <h3 class="pb-4 text-2xl leading-6 font-sans font-bold text-pink-700">
      {$translate('want_to_know_more')}
    </h3>
    <div class="mt-2">
      <div class="sm:flex sm:items-start">
        <div class="text-left">
          <p
            class="pb-2 pr-4 font-sans font-bold font-sm text-pink-700 text-4xl
            md:text-6xl"
          >
            {$translate('request_a')}
          </p>
        </div>

        <div class="text-left">
          <a
            href="mailto:quote@sassy.technology"
            class="font-sans font-bold font-sm text-pink-700 text-4xl
            md:text-6xl underline"
          >
            {$translate('demo')}
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
