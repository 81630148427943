<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      automating_hotel_marketing: 'Automating hotel marketing',
      all_in_one_booking_solution: 'An all-in-one direct booking solution',
      spark_description:
        'Access to all major advertising channels (Google Adwords, Instagram, Facebook), a custom booking engine and a revenue management tool.',
      learn_more: 'Learn more',
      what_we_provide: 'What we provide',
      access_to_advertising_channels:
        'Access to all major advertising channels',
      captures_prices:
        '● Spark captures your prices and proprietary information, to bring to all the major advertising platforms.',
      dynamic_ads:
        '● Create dynamic ads where price updates automatically based on the best rates!',
      ad_management:
        '● An ad management service to help you review content, set a realistic budget and goals.',
      customized_booking_engine: 'Customized Booking Engine',
      blazing_fast:
        '● Blazing fast, made with the latest web technologies. Every additional second to load leads to less booking!',
      dynamic_landing_pages: '● Includes dynamic landing pages for your ads',
      many_more_features: '● Many more features coming. Ask us more!',
      revenue_management_tool: 'Revenue management tool',
      forecast_occupancy:
        '● Forecast occupancy and price based on historical data with our AI tool',
      set_the_best_price:
        '● Set the best pricing to maximize profit and see the results on our dashboard.',
      help_you_make_decisions:
        '● To help you make decisions, we provide you with pricing from Online Travel Agency and from your competitors.',
    },

    jp: {
      automating_hotel_marketing: 'ホテルマーケッティングを自動化する',
      all_in_one_booking_solution: 'SparkはWeb予約の三つソリューションです。',
      spark_description:
        '第一はネット広告プラットフォームのアクセス(Google Adwords、Instagram、Facebook)。第二はカスタムWeb予約サイト。第三は売上管理と予測ツールです。',
      learn_more: 'もっと詳しく',
      what_we_provide: '商品の詳細',
      access_to_advertising_channels: '広告プラットフォームのアクセス',
      captures_prices:
        'Spark値段と部屋情報をクローリングして、常に最新情報の広告が出せるように。',
      dynamic_ads: '情報を変更する時に自動的に広告に反映されるように。',
      ad_management:
        '必要な場合弊社では広告の専門家が予算と目標を設定にお手伝いします。',
      customized_booking_engine: 'Web予約エンジン',
      blazing_fast:
        '神速！最新Web技術で作られて、ロード時間を最適化しています。',
      dynamic_landing_pages: '広告の自動変更ランディングページも含めています',
      many_more_features: '色々他にもあります、もっと詳しくは相談しましょう',
      revenue_management_tool: '売上管理と予測ツール',
      forecast_occupancy: 'AIツールで顧客人数と最高部屋値段を予測できます',
      set_the_best_price:
        '値段を最適化してダッシュボードで結果を簡単に見えます',
      help_you_make_decisions:
        '値段設定するためにOTAと競合他社の値段も提供します',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  import Image from 'svelte-image'
  import RequestDemo from '../components/RequestDemo.svelte'
  import FeatureDescriptor from '../components/spark/FeatureDescriptor.svelte'
</script>

<svelte:head>
  <title>Spark</title>
</svelte:head>
<div class="w-full bg-white text-left flex-shrink px-8 py-2">
  <div class="relative flex flex-wrap">
    <Image src="sparktext.png" alt="Spark logo" />
  </div>
</div>
<!-- hero tagline -->

<div class="w-full bg-white text-left flex-shrink py-2">
  <div class="flex bg-pink-900 overflow-hidden">
    <div class="bg-orange-200 md:w-1/2 p-6 md:p-12">
      <!-- automating hotel marketing is easy -->
      <h3 class="pb-4 text-4xl font-sans font-bold text-red-700">
        {$translate('automating_hotel_marketing')}
      </h3>
      <!-- an all in one -->
      <div class="mt-3 sm:mt-4">
        <div
          class="max-w-xl leading-5 font-sans font-bold text-pink-900 font-sans
          font-bold"
        >
          {$translate('all_in_one_booking_solution')}
        </div>
      </div>
      <!-- access to all -->
      <div class="mt-3 sm:mt-4">
        <div
          class="max-w-xl text-sm leading-5 text-pink-900 font-sans font-light"
        >
          {$translate('spark_description')}
        </div>
      </div>
      <!-- learn more -->
      <div class="mt-5 sm:mt-4 sm:flex-shrink-0">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            class="inline-flex items-center px-12 py-2 border border-transparent
            text-sm leading-5 font-sans font-semibold text-white bg-red-700
            hover:bg-red-600 focus:outline-none focus:border-red-700
            focus:shadow-outline-indigo active:bg-red-700 transition ease-in-out
            duration-150"
          >
            {$translate('learn_more')}
          </button>
        </span>
      </div>
    </div>
    <div class="hidden md:block pr-8 lg:w-1/2">
      <div
        class="md:w-full md:h-full transform -skew-x-12 bg-orange-500 px-8 pt-20"
      >
        <Image src="automation.jpg" alt="hotel marketing automation" />
      </div>
    </div>
  </div>

  <h3
    class="px-12 pt-24 pb-12 text-black text-2xl leading-relaxed font-sans
    font-black"
  >
    {$translate('what_we_provide')}
  </h3>

  <FeatureDescriptor
    title={$translate('access_to_advertising_channels')}
    textColor={'text-pink-900'}
    bgColor={'bg-orange-200'}
    points={[$translate('captures_prices'), $translate('dynamic_ads'), $translate('ad_management')]}
    invertFlow={false}
  >
    <Image src="code.jpg" alt="automated data crawling" />
  </FeatureDescriptor>

  <FeatureDescriptor
    title={$translate('customized_booking_engine')}
    textColor={'text-green-900'}
    bgColor={'bg-green-200'}
    points={[$translate('blazing_fast'), $translate('dynamic_landing_pages'), $translate('many_more_features')]}
    invertFlow={true}
  >
    <Image src="laptop.png" alt="customized booking engine" />
  </FeatureDescriptor>

  <FeatureDescriptor
    title={$translate('revenue_management_tool')}
    textColor={'text-teal-900'}
    bgColor={'bg-teal-200'}
    points={[$translate('forecast_occupancy'), $translate('set_the_best_price'), $translate('help_you_make_decisions')]}
    invertFlow={false}
  >
    <Image
      src="hotel-revenue-dashboard.png"
      alt="revenue management dashboard"
    />
  </FeatureDescriptor>

  <!-- request a demo -->
  <RequestDemo />
</div>
<!-- "want to chat?" section
  <div class="text-left ml-8 mt-48">
    <a
      href
      style="font-size: 120px;"
      class="pb-16 font-sans font-bold font-sm leading-9 tracking-tight text-black
      sm:text-6xl sm:leading-10 hover:underline"
    >
      Let's connect.
    </a>
    <p
      class="font-sans font-hairline ml-2 mt-3 mb-24 max-w-2xl mx-auto text-xl
      leading-7 text-black tracking-wide sm:mt-4 "
    >
      Talk to our consultant, free of charge!
    </p>
</div>-->
