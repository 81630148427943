<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      hero_text: 'Redefine the future of technology and design',
      hero_caption:
        'Sassy Technology bridges the gap between technology and modern user experience',
      hero_action: 'Learn more',
      work_with_st: 'Work with ST',
      explore_our_latest_products: 'Explore our latest products',
      the_future_of_alternative_investments:
        'The Future of Alternative Investments',
      union_description:
        'UNION helps traders find capital, wealth managers find strategies, and brokers find new customers',
      automating_hotel_marketing: 'Automating hotel marketing',
      spark_description:
        'Spark is an all-in-one direct booking solution. It gives you acccess to all major advertising channels, a custom booking engine and a revenue management tool',
      what_can_we_do_for_you: 'what can we do for you?',
      what_can_we_do_for_you_description:
        "We understand the intricacies of software development inside-out. That's why we can provide a wide range of services, from discovery phase and UX research, to building rock-solid Mobile and Web applications enriched with modern visual experiences.",
      ui_ux_design: 'UX & UI Design',
      ui_ux_design_description:
        'We design with the idea to make our apps fast, intuitive and beautiful.',
      development: 'Development',
      development_description:
        "We create scalable architectures and code that's easily maintained.",
    },

    jp: {
      hero_text: '一緒に技術とデザインの未来を創る',
      hero_caption: 'Sassy Technologyは最先端の技術とUXをユーザーに提供する',
      hero_action: '私達に関する',
      work_with_st: '一緒に協力する',
      explore_our_latest_products: '私達の最新のプロダクトの紹介',
      the_future_of_alternative_investments: 'アルタナティブな投資の未来',
      union_description:
        'UNIONトレーダーのために資金のアクセスを提供,投資家のために金融商品を提供,ブローカーのために新しいお客様を紹介するプラットフォームです',
      automating_hotel_marketing: 'ホテルマーケッティングを自動化する',
      spark_description:
        'SparkはWeb予約の三つソリューションです。第一はネット広告プラットフォームのアクセス。第二はカスタムWeb予約サイト。第三は売上管理と予測ツールです。',
      what_can_we_do_for_you: '一緒に何ができるの？',
      what_can_we_do_for_you_description:
        '弊社の専門はカスタムな開発です。開発に関しては色々提供しています、リアルタイムアップデートのページからUX調査まで',
      ui_ux_design: 'UIとUXデザイン',
      ui_ux_design_description:
        '弊社のデザインは動きの速さと分かりやすさを中心しています',
      development: '開発',
      development_description:
        '弊社のソフトウェアは維持しやすく、簡単にスケールできます',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  import Image from 'svelte-image'
  import DiscoverWhy from '../components/DiscoverWhy.svelte'
  import LetsTalk from '../components/LetsTalk.svelte'
</script>

<svelte:head>
  <title>Sassy Technology</title>
</svelte:head>
<div class="h-screen flex flex-col justify-center items-center text-center">
  <h1
    class="w-3/4 text-3xl md:text-5xl text-white font-sans font-semibold
    tracking-wide"
  >
    {$translate('hero_text')}
  </h1>
  <p
    class="w-3/4 md:w-2/3 pt-8 text-xl md:text-4xl text-white font-sans
    font-hairline tracking-wide"
  >
    {$translate('hero_caption')}
  </p>
  <div class="pt-8 flex items-center justify-center">
    <a
      href="/about"
      class="mr-2 text-white text-base font-sans font-medium tracking-wide
      hover:underline"
    >
      {$translate('hero_action')}
    </a>

    <svg
      width="11px"
      height="16px"
      viewBox="0 0 11 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Landing-Page-Copy"
          transform="translate(-842.000000, -1024.000000)"
          stroke="#FFFFFF"
          stroke-width="3"
        >
          <polyline
            id="Path-76"
            points="843.403646 1025 850.221354 1033.5 843.403646 1042"
          />
        </g>
      </g>
    </svg>
  </div>
</div>
<Image src="hero.png" alt="we are the future" />

<div class="my-16 text-center flex-shrink px-4 py-2">
  <h1 class="text-5xl text-white font-sans font-semibold tracking-wide">
    {$translate('explore_our_latest_products')}
  </h1>
</div>
<div
  class="w-full mx-auto h-screen text-center flex flex-col justify-center
  items-center"
  style="background-color: #7C1B13;"
>
  <a
    href="/union"
    class="no-underline hover:underline text-3xl text-white font-sans
    font-semibold tracking-wide md:p-8"
  >
    {$translate('the_future_of_alternative_investments')}
  </a>
  <p class="text-white w-3/4 md:w-1/2 md:text-lg">
    {$translate('union_description')}
  </p>
  <div class="w-full md:w-1/2">
    <Image width="648" height="388" src="union_mockup.png" alt="union" />
  </div>
</div>

<div
  class="w-full mx-auto h-screen text-center flex flex-col justify-center
  items-center"
  style="background-color: #E9B715;"
>
  <a
    href="/spark"
    class="w-3/4 no-underline hover:underline text-3xl text-white font-sans
    font-semibold tracking-wide"
  >
    {$translate('automating_hotel_marketing')}
  </a>
  <p class="text-white w-3/4 md:w-1/2 md:p-8">
    {$translate('spark_description')}
  </p>
  <div class="w-full md:w-1/2">
    <Image
      width="648"
      height="388"
      src="laptop.png"
      alt="spark customized booking engine"
    />
  </div>
</div>

<div class="w-full bg-white text-black text-center flex-shrink px-4 py-2">
  <div class="w-3/4 mx-auto mb-12">
    <h1
      class="flex-shrink text-6xl mt-12 font-sans font-semibold tracking-wide"
    >
      {$translate('what_can_we_do_for_you')}
    </h1>
    <div class="font-sans font-thin text-xl mx-auto">
      {$translate('what_can_we_do_for_you_description')}
    </div>
  </div>
</div>

<div class="flex flex-col md:flex-row w-full bg-white pb-12">

  <div>
    <div class="mt-12 flex justify-center">
      <div style="width: 100px;height: 100px">
        <Image src="design.svg" alt="our design services" />
      </div>
    </div>

    <div class="content-center text-center">
      <p class="text-black text-4xl font-sans font-medium">
        {$translate('ui_ux_design')}
      </p>

      <div
        class="inline-flex text-black font-sans font-thin justify-center
        text-center text-xl px-10 md:px-24 py-2"
      >
        {$translate('ui_ux_design_description')}
      </div>

    </div>
  </div>

  <div>
    <div class="mt-12 flex justify-center">
      <div style="width: 100px;height: 100px">
        <Image src="development.svg" alt="our development services" />
      </div>
    </div>

    <div class="content-center text-center">
      <p class="text-black text-4xl font-sans font-medium">
        {$translate('development')}
      </p>

      <div
        class="inline-flex text-black font-sans font-thin justify-center
        text-center text-xl px-10 mx:px-24 py-2"
      >
        {$translate('development_description')}
      </div>
    </div>
  </div>

</div>

<DiscoverWhy />

<LetsTalk />
