<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      work_with_st: 'Work with ST',
      follow_us_on_behance: 'Follow us on Behance',
      discover_why: 'Discover why and design what people',
      discover_why_link: 'need',
    },
    jp: {
      work_with_st: '一緒に協力する',
      follow_us_on_behance: 'Behanceのプロフィール',
      discover_why: 'ユーザーの動機とニーズを',
      discover_why_link: '発見',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  export let bgClass = ''
  export let supportTextClass = 'text-gray-300'
  export let mainTextClass = 'text-white'
</script>

<div class="{bgClass} flex-wrap w-full">
  <div class="px-6 pt-8 flex justify-between">
    <a
      href="/contact"
      class="font-sans font-thin {supportTextClass} underline
      focus:text-gray-900 transition duration-150 ease-in-out"
    >
      {$translate('work_with_st')}
    </a>
    <a
      href="https://www.behance.net/Sassytech"
      class="font-sans font-thin {supportTextClass} underline
      focus:text-gray-900 transition duration-150 ease-in-out"
    >
      {$translate('follow_us_on_behance')}
    </a>
  </div>

  <div class="p-8 md:p-24">
    <h2
      class="{mainTextClass} text-4xl md:text-10xl font-sans font-thin
      tracking-wide leading-tight"
    >
      {$translate('discover_why')}
      <a
        href="/contact"
        class="font-sans font-semibold underline underline-transparent
        hover:underline"
      >
        {$translate('discover_why_link')}
      </a>
    </h2>
  </div>
</div>
