<script>
  import Image from 'svelte-image'
  import FeatureDescriptor from '../components/union/FeatureDescriptor.svelte'
  import RequestDemo from '../components/RequestDemo.svelte'
</script>

<svelte:head>
  <title>Union</title>
</svelte:head>
<div class="w-full bg-white text-left flex-shrink py-2 pt-12">
  <div class="relative flex flex-wrap">
    <Image src="herounion.png" alt="" />
  </div>

  <h3
    class="px-12 pt-24 pb-12 text-black text-2xl leading-relaxed font-sans
    font-black"
  >
    We help
  </h3>

  <FeatureDescriptor
    title={'Traders find capital'}
    bgColor={'bg-orange-200'}
    textColor={'text-orange-700'}
    svgColor={'#AB2C1B'}
    points={['Interrogate every trade with a deeply searchable interface.', 'Plethora of data available for insights on your strategy']}
  />

  <FeatureDescriptor
    title={'Wealth Managers find Strategies'}
    bgColor={'bg-yellow-200'}
    textColor={'text-green-700'}
    svgColor={'#3C8D13'}
    points={['Real time update of portfolio value', 'Invest and disinvest at the click of a button', 'Customize risk on any strategy through our active margin technology']}
  />

  <FeatureDescriptor
    title={'Brokers find customers'}
    bgColor={'bg-teal-200'}
    textColor={'text-teal-700'}
    svgColor={'#246C6D'}
    points={['Increase volume traded on your books', 'Automate new client onboarding', 'Audit framework for regulatory purposes']}
  />

  <!-- request a demo -->

  <RequestDemo />

  <!-- "want to chat?" section
  <div class="text-left ml-8 mt-48">
    <a
      href
      style="font-size: 120px;"
      class="pb-16 font-sans font-bold font-sm leading-9 tracking-tight text-black
      sm:text-6xl sm:leading-10 hover:underline"
    >
      Let's connect.
    </a>
    <p
      class="font-sans font-hairline ml-2 mt-3 mb-24 max-w-2xl mx-auto text-xl
      leading-7 text-black tracking-wide sm:mt-4 "
    >
      Talk to our consultant, free of charge!
    </p>
</div>-->
</div>

<div class="relative bg-black">
  <div class="bg-black pb-12 px-4 sm:px-6 lg:pb-24 lg:px-8 xl:pl-12">
    <div class="max-w-lg mx-auto lg:max-w-none" />
  </div>
</div>
