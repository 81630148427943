<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      startup_founder:
        '● You’re an enthusiastic startup founder who wants a minimum viable product',
      c_level_executive:
        ' ● You’re a C-level executive who wants to modernize the company with the latest technologies',
      product_manager:
        '● You’re a product manager who wants to increase user engagement.',
      marketing_manager:
        '● You’re a marketing manager who’s looking to impress target users',
    },

    jp: {
      startup_founder: 'あなたは企業家でプロトタイプを創りたい',
      c_level_executive: 'あなたは取締役で最新の技術を会社に入れたい',
      product_manager:
        'あなたはプロダクトマネージャーでユーザーエンゲージメントを高めたい',
      marketing_manager:
        'あなたはマーケティングマネージャーでターゲットユーザーを興奮させたい',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  import Image from 'svelte-image'
  import LetsTalk from '../components/LetsTalk.svelte'
</script>

<svelte:head>
  <title>Contact</title>
</svelte:head>

<div class="max-w-screen-xl mx-auto">
  <div class="relative flex flex-wrap">
    <Image src="sayhello.png" alt="" />
  </div>

  <div
    class="mt-6 grid pt-10 pb-10 grid-cols-1 md:grid-cols-2 md:col-gap-12
    md:row-gap-12"
  >
    <span
      class="inline-flex items-center px-16 py-16 text-sm font-thin leading-5
      bg-red-800 text-red-100"
    >
      {$translate('startup_founder')}
    </span>
    <span
      class="inline-flex items-center px-16 py-16 text-sm font-thin leading-5
      bg-yellow-600 text-yellow-100"
    >
      {$translate('c_level_executive')}
    </span>
    <span
      class="inline-flex items-center px-16 py-16 text-sm font-thin leading-5
      bg-blue-800 text-indigo-100"
    >
      {$translate('product_manager')}
    </span>
    <span
      class="inline-flex items-center px-16 py-16 text-sm font-thin leading-5
      bg-green-800 text-green-100"
    >
      {$translate('marketing_manager')}
    </span>
  </div>

  <LetsTalk
    textClass={'text-gray-100'}
    bgClass={'bg-black'}
    hideWorkWithSt={true}
  />
</div>
