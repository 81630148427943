<script>
  export let title
  export let bgColor
  export let textColor
  export let svgColor
  export let points
</script>

<div
  class="flex flex-col md:flex-row items-center justify-between {bgColor} p-8"
>
  <div>
    <h3 class="pb-4 text-2xl leading-6 font-sans font-bold {textColor}">
      {title}
    </h3>
    {#each points as point}
      <div class="mt-2 flex justify-between">
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Union" transform="translate(-101.000000, -1734.000000)">
              <g id="Group" transform="translate(101.000000, 1734.000000)">
                <rect
                  id="Rectangle"
                  fill={svgColor}
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  rx="12"
                />
                <polyline
                  id="Path-81"
                  stroke="#FFFFFF"
                  stroke-width="2"
                  points="6.5 12 10.5 16 18.5 8"
                />
              </g>
            </g>
          </g>
        </svg>
        <div class="w-5/6 md:ml-4 md:w-full text-sm leading-5 {textColor}">
          {point}
        </div>
      </div>
    {/each}
  </div>

  <div class="mt-8">
    <span class="inline-flex rounded-md shadow-sm">
      <button
        type="button"
        class="inline-flex items-center px-12 py-2 border border-transparent
        text-sm leading-5 font-sans font-semibold text-white bg-pink-900
        hover:bg-pink-800 focus:outline-none focus:border-pink-900
        focus:shadow-outline-indigo active:bg-pink-900 transition ease-in-out
        duration-150"
      >
        Learn more
      </button>
    </span>
  </div>
</div>
