<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      hero_text: 'Redefine the future of technology and design',
      hero_caption:
        'Sassy Technology bridges top technology solution with modern user experience design.',
      hero_action: 'Learn more',
      work_with_st: 'Work with ST',
      follow_us_on_behance: 'Follow us on Behance',
      discover_why: 'Discover why and design what people',
      discover_why_link: 'need',
    },
    jp: {
      hero_text: '一緒に技術とデザインの未来を創る',
      hero_caption: 'Sassy Technologyは最先端の技術とUXをユーザーに提供する',
      hero_action: '私達に関する',
      work_with_st: '一緒に協力する',
      follow_us_on_behance: 'Behanceのプロフィール',
      discover_why: 'ユーザーの動機とニーズを',
      discover_why_link: '発見',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  import DiscoverWhy from '../components/DiscoverWhy.svelte'
  import LetsTalk from '../components/LetsTalk.svelte'
</script>

<svelte:head>
  <title>Services</title>
</svelte:head>
<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
  <div class="relative flex flex-wrap">
    <img src="What we do.png" alt="" />
  </div>

  <div class="mt-12 mr-4 mb-12 text-left flex-shrink py-2">
    <h1 class="text-2xl text-white font-sans font-semibold tracking-wide">
      We build software that boosts productivity for businesses and simplifies
      customers’ lives. Our products are carefully designed for the end user and
      brought to life with elegantly written code.
    </h1>
  </div>

  <!-- UIUX -->

  <div class="mb-24 mt-24 lg:grid lg:grid-cols-12 lg:gap-8">
    <div
      class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
    >
      <h2
        class="mt-1 text-4xl tracking-wide leading-10 font-extrabold text-white
        sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl"
      >
        Product design
      </h2>
      <p
        class="mt-3 text-base text-red-600 sm:mt-5 sm:text-xl lg:text-lg
        xl:text-xl"
      >
        User Interface / User Experience
      </p>
      <div
        class="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
      >
        <p class="text-base font-medium text-white">
          While architecture and coding is certainly important, UI/UX design is
          what truly sets the tone for a digital product. Effective design
          enables users to understand and interact with technology with ease and
          understanding.
        </p>

      </div>
    </div>
    <div
      class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0
      lg:col-span-6 lg:flex lg:items-center"
    >
      <img src="lightning.png" alt="illustrates our product design service" />
    </div>
  </div>

  <!-- web development -->
  <div class="mb-24 mt-24 lg:grid lg:grid-cols-12 lg:gap-8">
    <div
      class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0
      lg:col-span-6 lg:flex lg:items-center"
    >
      <img src="computer.png" alt="illustrates our web development service" />
    </div>
    <div
      class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
    >
      <h2
        class="mt-1 text-4xl tracking-wide leading-10 font-extrabold text-white
        sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl"
      >
        Web development
      </h2>
      <p
        class="mt-3 text-base text-orange-600 sm:mt-5 sm:text-xl lg:text-lg
        xl:text-xl"
      >
        Frontend / BackEnd
      </p>
      <div
        class="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
      >
        <p class="text-base font-medium text-white">
          While architecture and coding is certainly important, design is what
          truly sets the tone for a digital product. Effective design enables
          users to understand and interact with technology with ease and
          understanding.
        </p>

      </div>
    </div>

  </div>

  <!-- AI -->

  <div class="mb-24 mt-24 lg:grid lg:grid-cols-12 lg:gap-8">
    <div
      class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
    >
      <h2
        class="mt-1 text-4xl tracking-wide leading-10 font-extrabold text-white
        sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl"
      >
        Artificial Intelligence
      </h2>
      <p
        class="mt-3 text-base text-red-600 sm:mt-5 sm:text-xl lg:text-lg
        xl:text-xl"
      >
        Data Crawling
      </p>
      <div
        class="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
      >
        <p class="text-base font-medium text-white">
          The technologies we use are well-documented, scalable and secure.
          Whether it’s a web app, custom backend system or an API, we create
          architectures that best suit your digital products.
        </p>

      </div>
    </div>
    <div
      class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0
      lg:col-span-6 lg:flex lg:items-center"
    >
      <img src="ai.png" alt="illustrates our ai service" />
    </div>
  </div>

  <!-- QT -->

  <div class="mb-24 mt-24 lg:grid lg:grid-cols-12 lg:gap-8">
    <div
      class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0
      lg:col-span-6 lg:flex lg:items-center"
    >
      <img src="qt.png" alt="illustrates our quality testing service" />
    </div>
    <div
      class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left"
    >
      <h2
        class="mt-1 text-4xl tracking-wide leading-10 font-extrabold text-white
        sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl"
      >
        Quality Testing
      </h2>
      <p
        class="mt-3 text-base text-orange-600 sm:mt-5 sm:text-xl lg:text-lg
        xl:text-xl"
      >
        Assurance / Control / Testing
      </p>
      <div
        class="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
      >
        <p class="text-base font-medium text-white">
          We’ve built a reputation around delivering exceptional user
          experiences. Our QA team plays a major role in ensuring our processes
          and the final product meet the expectations and demands of our
          clients.
        </p>

      </div>
    </div>

  </div>

</div>
<!-- rest -->

<div class="w-full bg-white text-left flex-shrink px-16 py-2">
  <!-- creative -->
  <img class="pt-24 pb-24" src="creative.png" alt="" />

  <!-- lead by raphael and yining -->

  <div
    class="mt-6 grid gap-16 border-t-8 border-black pt-10 pb-10 lg:grid-cols-2
    lg:col-gap-1 lg:row-gap-12"
  >
    <div>
      <h3 class="py-12 text-xl leading-relaxed font-semibold">
        ● We put human behavior at the center of every digital product design.
        Our services is to provide leading UI/UX design and Technology
        solutions. Things are moving fast here, but there’s one thing that never
        changes — our commitment to people who use what we create.
      </h3>
    </div>

  </div>

</div>

<!-- white description -->

<DiscoverWhy />

<LetsTalk />
