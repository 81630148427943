<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      other_language: '日本語',
      other_locale: 'jp',
      home: 'Home',
      what_we_do: 'What we do',
      about: 'About',
      who_we_are: 'Who we are',
      products: 'Products',
      what_we_make: 'What we make',
      services: 'Services',
      what_we_provide: 'What we provide',
      contact: 'Contact',
      say_hello: 'Say hello',
    },
    jp: {
      other_language: 'English',
      other_locale: 'en',
      home: 'Home',
      what_we_do: '私達がやっている事',
      about: 'About',
      who_we_are: '私達に関する',
      products: 'Products',
      what_we_make: '私達が造っている物',
      services: 'Services',
      what_we_provide: '一緒に物を造る',
      contact: 'Contact',
      say_hello: '私たちに連絡する',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { isActive, url } from '@sveltech/routify'
  import { translate } from 'svelte-intl'

  let menuOpen = false
  function toggleMenuOpen() {
    menuOpen = !menuOpen
  }
  let dropdownOpen = false
  function toggleDropdownOpen() {
    dropdownOpen = !dropdownOpen
  }
</script>

<nav class="bg-white">
  <div class="hidden h-12 md:h-24 md:grid grid-cols-12 gap-2">
    <div class="col-span-1 flex items-center">
      <div class="w-full flex flex-col items-center">
        <div
          class="bg-black flex justify-center border border-black hover:bg-white
          text-white hover:text-black transition duration-150 ease-in-out"
          style="width: 90px; height: 90px;"
        >
          <div class="flex items-center">
            <a href="." class="font-sans font-black">ST</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-2 flex items-center">
      <div class="w-full flex flex-col items-center">
        <a
          href={$url('/index')}
          class="font-sans font-black text-gray-900 no-underline border p-1
          border-transparent focus:text-gray-900 transition duration-150
          ease-in-out"
        >
          {$translate('home')}
        </a>
        <div class="flex justify-center">
          <p
            class="text-gray-900 font-hairline text-xs {$isActive('/index') ? 'border-b-4 border-black' : ''}"
          >
            {$translate('what_we_do')}
          </p>
        </div>
      </div>
    </div>

    <div class="col-span-2 flex items-center">
      <div class="w-full flex flex-col items-center">
        <a
          href={$url('/about')}
          class="font-sans font-black text-gray-900 no-underline border p-1
          border-transparent focus:text-gray-900 transition duration-150
          ease-in-out"
        >
          {$translate('about')}
        </a>
        <div class="flex justify-center">
          <p
            class="text-gray-900 font-hairline text-xs {$isActive('/about') ? 'border-b-4 border-black' : ''}"
          >
            {$translate('who_we_are')}
          </p>
        </div>
      </div>
    </div>

    <div
      class="col-span-2 flex items-center cursor-pointer"
      on:click={toggleDropdownOpen}
    >
      <div class="relative w-full flex flex-col items-center">
        <p
          class="font-sans font-black text-gray-900 no-underline border p-1
          border-transparent focus:text-gray-900 transition duration-150
          ease-in-out"
        >
          {$translate('products')}
        </p>
        <div class="flex justify-center">
          <p class="text-gray-900 font-hairline text-xs">
            {$translate('what_we_make')}
          </p>
        </div>
        <div
          class="w-full absolute {dropdownOpen ? 'block' : 'hidden'} text-center
          bg-white"
          style="top: 85px"
        >
          <a
            href={$url('/union')}
            class="block font-sans font-black text-gray-900 no-underline border
            p-1 border-transparent focus:text-gray-900 transition duration-150
            ease-in-out"
          >
            Union
          </a>
          <a
            href={$url('/spark')}
            class="block font-sans font-black text-gray-900 no-underline border
            p-1 border-transparent focus:text-gray-900 transition duration-150
            ease-in-out"
          >
            Spark
          </a>
        </div>
      </div>
    </div>

    <div class="col-span-2 flex items-center">
      <div class="w-full flex flex-col items-center">
        <a
          href={$url('/services')}
          class="font-sans font-black text-gray-900 no-underline border p-1
          border-transparent focus:text-gray-900 transition duration-150
          ease-in-out"
        >
          {$translate('services')}
        </a>
        <div class="flex justify-center">
          <p
            class="text-gray-900 font-hairline text-xs {$isActive('/services') ? 'border-b-4 border-black' : ''}"
          >
            {$translate('what_we_provide')}
          </p>
        </div>
      </div>
    </div>

    <div class="col-span-2 flex items-center">
      <div class="w-full flex flex-col items-center">
        <a
          href={$url('/contact')}
          class="font-sans font-black text-gray-900 no-underline border p-1
          border-transparent focus:text-gray-900 transition duration-150
          ease-in-out"
        >
          {$translate('contact')}
        </a>
        <div class="flex justify-center">
          <p
            class="text-gray-900 font-hairline text-xs {$isActive('/contact') ? 'border-b-4 border-black' : ''}"
          >
            {$translate('say_hello')}
          </p>
        </div>
      </div>
    </div>

    <div class="col-span-1 flex items-center">
      <div class="w-full flex flex-col items-center">
        <button
          class="text-gray-900 font-sans font-semibold border-b-2
          border-transparent text-xs transition duration-150 ease-in-out"
          on:click={(e) => locale.set($translate('other_locale'))}
        >
          {$translate('other_language')}
        </button>
      </div>
    </div>
  </div>

  <div class="flex justify-between items-center lg:hidden">
    <div class="ml-4">
      <button
        class="text-gray-900 font-sans font-semibold border-b-2
        border-transparent text-xs transition duration-150 ease-in-out"
        on:click={(e) => locale.set($translate('other_locale'))}
      >
        {$translate('other_language')}
      </button>
    </div>

    <button
      on:click={toggleMenuOpen}
      class="inline-flex items-center justify-center p-2 rounded-md
      text-gray-900 hover:text-white hover:bg-gray-700 focus:outline-none
      focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
    >
      <svg
        class="h-6 w-6"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          class={menuOpen ? 'hidden' : 'inline-flex'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
        <path
          class={menuOpen ? 'inline-flex' : 'hidden'}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
  <div
    class="z-10 col-span-12 {menuOpen ? 'block' : 'hidden'} lg:hidden bg-white"
  >
    <div class="pb-2">
      <div class="w-full flex flex-col items-center">
        <a
          href={$url('/index')}
          class="font-sans font-black text-gray-900 no-underline p-1
          focus:text-gray-900 transition duration-150 ease-in-out {$isActive('/index') ? 'border-b-4 border-black' : ''}"
        >
          {$translate('home')}
        </a>
      </div>

      <div class="w-full flex flex-col items-center pt-4">
        <a
          href={$url('/about')}
          class="font-sans font-black text-gray-900 no-underline p-1
          focus:text-gray-900 transition duration-150 ease-in-out {$isActive('/about') ? 'border-b-4 border-black' : ''}"
        >
          {$translate('about')}
        </a>
      </div>

      <div class="relative w-full flex flex-col items-center pt-4">
        <p
          class="font-hairline text-gray-900 no-underline p-1
          focus:text-gray-900 transition duration-150 ease-in-out"
        >
          {$translate('products')}
        </p>
        <div class="flex flex-col justify-center text-center">
          <a
            href={$url('/union')}
            class="block font-sans font-black text-gray-900 no-underline border
            p-1 border-transparent focus:text-gray-900 transition duration-150
            ease-in-out"
          >
            Union
          </a>
          <a
            href={$url('/spark')}
            class="block font-sans font-black text-gray-900 no-underline border
            p-1 border-transparent focus:text-gray-900 transition duration-150
            ease-in-out"
          >
            Spark
          </a>
        </div>
      </div>

      <div class="w-full flex flex-col items-center pt-4">
        <a
          href={$url('/services')}
          class="font-sans font-black text-gray-900 no-underline p-1
          focus:text-gray-900 transition duration-150 ease-in-out {$isActive('/services') ? 'border-b-4 border-black' : ''}"
        >
          {$translate('services')}
        </a>
      </div>

      <div class="w-full flex flex-col items-center pt-4">
        <a
          href={$url('/contact')}
          class="font-sans font-black text-gray-900 no-underline p-1
          focus:text-gray-900 transition duration-150 ease-in-out {$isActive('/contact') ? 'border-b-4 border-black' : ''}"
        >
          {$translate('contact')}
        </a>
      </div>
    </div>
  </div>
</nav>
