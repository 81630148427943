<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      work_with_st: 'Work with ST',
      lets_talk: "Let's talk",
      clik_to_send_mail: 'Hit us up by clicking the link!',
    },
    jp: {
      work_with_st: '一緒に協力する',
      lets_talk: '一緒に話しましょう',
      clik_to_send_mail: 'クリックしてメールを送信',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  export let textClass = 'text-black'
  export let bgClass = 'bg-white'
  export let hideWorkWithSt = false
</script>

<div
  class="{hideWorkWithSt ? 'hidden' : ''} px-6 pt-8 flex justify-between {bgClass}"
>
  <a
    href="/contact"
    class="font-sans font-thin text-black no-underline hover:underline
    transition duration-150 ease-in-out"
  >
    {$translate('work_with_st')}
  </a>
</div>

<div class="{bgClass} text-center pt-8 md:pt-40">
  <div class="w-3/4 mx-auto">
    <a
      href="mailto:hi@sassy.technology"
      class="font-sans font-bold font-sm {textClass} tracking-tight text-6xl
      underline md:text-10xl"
    >
      {$translate('lets_talk')}
    </a>
    <p class="font-sans font-hairline mt-6 mb-12 pb-24 text-xl {textClass}">
      {$translate('clik_to_send_mail')}
    </p>
  </div>
</div>
