<script>
  export let title
  export let textColor
  export let bgColor
  export let points
  export let invertFlow
</script>

<div class="w-full bg-white text-left py-5 md:py-10">
  <div
    class="flex flex-col md:flex-row {invertFlow ? 'md:flex-row-reverse' : ''}
    {bgColor}"
  >
    <div class="md:w-1/2 h-72 md:h-96 p-6">
      <slot />
    </div>
    <div class="p-6 md:p-12 md:w-1/2">
      <h3 class="pb-4 text-2xl leading-6 font-sans font-bold {textColor}">
        {title}
      </h3>
      {#each points as point}
        <div class="mt-4">
          <div class="max-w-xl text-sm leading-5 {textColor}">{point}</div>
        </div>
      {/each}
    </div>
  </div>
</div>
