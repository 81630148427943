<script context="module">
  import { locale, translations, getBrowserLocale } from 'svelte-intl'

  // If you want to split your bundle, you can call this multiple times,
  // the dictionaries will be merged.
  translations.update({
    en: {
      we_create_useful_software:
        "We help our clients create useful software with user-friendly design. we aim at shaping the future, let's do it together!",
      engineering_art_psychology:
        'Sassy Technology works at the intersection of engineering, art and psychology to change the way people work, think, and communicate',
    },
    jp: {
      we_create_useful_software:
        '弊社はお客様のためにソフトウェアを造っている。有意義の物を造るために弊社ユーザー重心主義。ソフトウェアで未来を一緒に創りましょう',
      engineering_art_psychology:
        'Sassy Technologyは技術、アートと心理学を利用して人の働き方、考え方、交流のし方を進化させる',
    },
  })

  locale.set(getBrowserLocale('en')) // try to use window.navigator.language
</script>

<script>
  import { translate } from 'svelte-intl'
  import Image from 'svelte-image'
  import DiscoverWhy from '../components/DiscoverWhy.svelte'
</script>

<svelte:head>
  <title>About</title>
</svelte:head>

<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
  <div class="mx-auto relative flex flex-wrap overflow-hidden">
    <Image src="bubble-up.png" alt="" />
    <Image src="bubble.png" alt="" />
    <div class="hidden text-white md:flex absolute flex-wrap">
      <svg
        width="1265px"
        height="441px"
        viewBox="0 0 1265 441"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          font-family="EurostileBold, Eurostile"
          font-size="460"
          font-weight="bold"
          letter-spacing="1.44230769"
        >
          <g
            id="About"
            transform="translate(-59.000000, -360.000000)"
            fill="#FFFFFF"
          >
            <text id="Sassy">
              <tspan x="44" y="697">Sassy</tspan>
            </text>
          </g>
        </g>
      </svg>
      <svg
        class="flex justify-end"
        width="1006px"
        height="350px"
        viewBox="0 0 1006 350"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          font-family="EurostileBold, Eurostile"
          font-size="460"
          font-weight="bold"
          letter-spacing="6"
        >
          <g
            id="About"
            transform="translate(-374.000000, -918.000000)"
            fill="#FFFFFF"
          >
            <text id="Tech">
              <tspan x="372" y="1265">Tech</tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div class="py-12 md:py-24 border-t-8 border-b-8 border-gray-100">
    <h3 class="text-2xl leading-relaxed font-semibold text-gray-400">
      {$translate('we_create_useful_software')}
    </h3>
  </div>

  <!-- idealism -->
  <div class="py-12 md:py-24">
    <Image src="idealism.png" alt="" />
  </div>

  <!-- lead by raphael and yining -->

  <div class="py-12 md:py-24 border-t-8 border-gray-100">
    <h3 class="text-xl leading-relaxed font-semibold text-gray-400">
      {$translate('engineering_art_psychology')}
    </h3>
  </div>
</div>

<DiscoverWhy
  bgClass="bg-white"
  supportTextClass="text-gray-900"
  mainTextClass=""
/>
